.kaigie-container {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  color: #666;
  margin-top: 100px;
  /* width: 98%;
  margin-left: 1%;
  margin-right: 1%; */
}
.header-background {
  background-color: gainsboro;
}
.site-header-background {
  background-color: '#d8e0aa';
}
.page-header {
  background-color: whitesmoke;
}

.item-title {
  color: steelblue;
}

.kaigie-card-body {
  flex: 1 1 auto;
  padding: 0.25rem;
}
.kaigie-expert-card-body {
  flex: 1 1 auto;
  padding: 0.25rem;
  width: 96%;
}
.kaigie-select {
  width: 80%;
}
