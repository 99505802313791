.expert-container {
  width: 99%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 0.5%;
  margin-left: 0.5%;
  font-size: 0.9em;
}

.expert-detail-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.25rem;
}
.expert-detail-container {
  width: 99%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  font-size: 0.9em;
}
.expert-card {
  height: 600px;
  max-height: 600px;
  width: 96%;
}

.expert-thumbnail {
  height: 40px;
  width: 40px;
  padding-left: 0.5em;
}

.fading-text {
  font-size: 0.75em;
  height: 175px;
  max-height: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  content: '';
  background: -webkit-linear-gradient(top, #000 55%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.expert-info-grid {
  height: 600px;
  max-height: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kaigie-expert-name {
  font-size: 0.9em !important;
  padding-left: 0.5em !important;
}

.primary-specialty {
  font-size: 0.8em !important;
  font-weight: 500;
}

.expert-footer {
  position: absolute;
  bottom: 0;
  margin-left: 1.25em;
  margin-right: 1.25em;
  padding-top: 15px;

  z-index: 20;
  width: 94%;
}

.expert-address {
  font-size: 0.78em !important;
  margin-bottom: 0px;
}

.text-75 {
  font-size: 0.75em !important;
}

.expert-heading {
  background-color: #f8f9fa;
  width: 100%;
}

.img-container {
  width: 80px;
  height: 115px;
  overflow: hidden;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: portrait) {
  .phone-margins {
    width: 99% !important;
    margin-left: 0.5% !important;
    margin-right: 0.5% !important;
    justify-content: center !important;
  }

  .expert-card {
    height: 450px;
    max-height: 450px;
    width: 95% !important;
    -webkit-align-self: center; /* Safari 7.0+ */
    align-self: center;
  }

  .portrait-width {
    /*width: 320px;*/
  }
}
